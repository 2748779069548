import * as React from "react"
import { PageProps, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { DataPropsListGeneric } from "../contracts/data-props-list"
import MainCard from "../components/main-card"
import { MarkdownRemark } from "../contracts/markdown-remark"

interface OgloszeniaMarkdownRemark extends MarkdownRemark {
  frontmatter: {
    title: string
    description?: string
    date?: string
    thumbnail?: string
    sunday?: string
    layout: string
  }
}

const Ogloszenia: React.FC<
  PageProps<DataPropsListGeneric<OgloszeniaMarkdownRemark>>
> = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || "Tytuł"
  const posts = data.allMarkdownRemark.nodes
  const pageTitle = "Ogłoszenia duszpasterskie"

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title={pageTitle} />
        <p>Nie ma żadnych wpisów.</p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={pageTitle} />
      <h1>{pageTitle}</h1>
      <div>
        <div className="max-w-7xl mx-auto">
          <div className="max-w-2xl mx-auto lg:max-w-none">
            <div className="space-y-12 lg:space-y-0 lg:grid lg:grid-cols-1 lg:gap-x-6 gap-y-10">
              {posts.map(post => {
                return (
                  <MainCard
                    key={post.fields.slug}
                    thumbnail={post.frontmatter.thumbnail}
                    title={
                      post.frontmatter.sunday ||
                      post.frontmatter.title ||
                      post.fields.slug
                    }
                    date={post.frontmatter.date}
                    slug={post.fields.slug}
                    description={post.excerpt || post.frontmatter.description}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Ogloszenia

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "ogloszenia" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD MMMM YYYY", locale: "pl")
          title
          description
          sunday
        }
      }
    }
  }
`
